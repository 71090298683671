.subscription-portal--container {
  display: flex;
  justify-content: center;
  height: 100%;
}
.subscription-portal--container iframe {
  border: none;
  height: 100%;
  width: 100%;
  background: url('data:image/svg+xml;charset=utf-8,<svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 100% 100%"><text fill="%232bab2b" x="50%" y="50%" font-family="\'Lucida Grande\', sans-serif" font-size="24" text-anchor="middle">LOADING...</text></svg>')
    0 0 no-repeat;
}

.subscription-portal--container iframe html {
  overflow: hidden;
}

.subscription-portal--no-subscription {
  padding: 50px;
  font-size: 20px;
}
