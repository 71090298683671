.materials-table {
  .RaDatagrid-root,
  .RaEditableDatagrid-root {
    width: 100%;

    td,
    th {
      padding-left: 2px;
      padding-right: 2px;
    }
    td:has(*.fixed) {
      width: 130px;
    }
    td:has(*.fixed2x) {
      width: 260px;
    }
    td.column-undefined {
      width: 10%;
    }
    div:has(input) {
      width: 100%;
    }
    td:has(input),
    td.RaRowForm-actionColumn {
      vertical-align: top;
    }
    td.RaRowForm-actionColumn {
      width: 10%;
      padding-top: 18px;
    }
  }

  .materials-footer {
    padding-top: 4px;
    justify-content: flex-end;
    width: 100%;
    height: 30px;
    gap: 0;
    * {
      padding: 4px 2px 4px 2px;
      vertical-align: center;
    }
    *.fixed {
      width: 130px;
    }
    *.fixed2x {
      width: 260px;
    }
    .total {
      margin-right: 8px;
    }
    .selected {
      background: #eeeeee;
    }
    .action {
      width: 10%;
      justify-content: flex-start;
      div {
        vertical-align: center;
      }
      min-height: 30px;
      .refill {
        padding: 4px 16px 4px 16px;
      }
    }
  }
}
