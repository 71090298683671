.lucid-embed {
  width: 100%;
  border: none;
  background: url('data:image/svg+xml;charset=utf-8,<svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 100% 100%"><text fill="%232bab2b" x="50%" y="50%" font-family="\'Lucida Grande\', sans-serif" font-size="24" text-anchor="middle">LOADING...</text></svg>')
    0 0 no-repeat;
}

.lucid-embed--create {
  height: calc(100vh - 245px);
}

.lucid-embed--show {
  height: calc(100vh - 140px);
}

.lucid-embed--edit {
  height: calc(100vh - 295px);
}

.lucid-embed--dashboard {
  height: calc(100vh - 105px);
}

.lucid-unavailable-message {
  margin-left: 10px;
  & .lucid-unavailable-message--header {
    color: #f14158;
  }
}

.lucid-diagram-required {
  color: #d32f2f;
  font-weight: 400;
  font-size: 0.75rem;
  line-height: 1.66;
  text-align: left;
  margin-top: 4px;
  margin-right: 14px;
  margin-bottom: 0;
  margin-left: 14px;
}
