.fpms-wrapper {
  padding-top: 10px;
}

.fpms-wrapper iframe {
  width: calc(100vw - 260px - 16px);
  height: calc(100vh - 14px - 52px);
  border: none;
}

.circular-wrapper {
  display: flex;
  justify-content: center;
}
