.gantt-chart-edit-toolbar {
  color: #2bab2b;
  background-color: rgb(221, 241, 221);
  position: absolute !important;
  height: 48px;
  min-height: 48px;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: -100;
  padding-left: 24px !important;
  padding-right: 24px !important;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0.5ms;
  transition-property: height, min-height, transform;
  transform: translateY(48px);
}

.gantt-chart-edit-toolbar.visible {
  z-index: 1;
  transform: translateY(0px);
}
